import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/project/node_modules/gatsby-theme-docz/src/base/Layout.js";
import ContrastChecker from '~/components/ContrastChecker';
import PageHeader from '~/components/PageHeader';
import { Heading3, Paragraph } from '@entur/typography';
import { colors } from '@entur/tokens';
import { GridItem, GridContainer } from '@entur/grid';
import { ValidationErrorFilledIcon, ValidationSuccessFilledIcon } from '@entur/icons';
import './farger.scss';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader" />
    <p>{`I henhold til lovverket er vi pålagt å følge `}<a parentName="p" {...{
        "href": "https://uu.difi.no/krav-og-regelverk/losningsforslag-web/kontrast"
      }}>{`forskrift om universell utforming`}</a>{` når vi utvikler våre digitale løsninger. Når det gjelder farger, må all tekst derfor ha tilstrekkelig kontrast mot bakgrunnen for å sikre god lesbarhet. Dette er viktig for alle brukere, særlig under vanskelige lysforhold.
Kravet til kontrast er avhengig av størrelsen på teksten/objektene:`}</p>
    <ul>
      <li parentName="ul">{`Stor tekst: kontrastkrav 3.0:1`}</li>
      <li parentName="ul">{`Liten tekst: kontrastkrav 4.5:1`}</li>
      <li parentName="ul">{`Ved normal teksttykkelse er stor tekst 18 punkt og større.`}</li>
      <li parentName="ul">{`Ved fet tekststil er stor tekst 14 punkt og større.`}</li>
    </ul>
    <p>{`Stor tekst er det som er dobbelt så stor som brødtekst, det vil si at mesteparten av tekstinnholdet på våre nettsider krever `}<strong parentName="p">{`kontrastkrav 4.5:1`}</strong>{`. Grafiske objekter som er viktig for å forstå innholdet, har en kontrast på minst `}<strong parentName="p">{`3:1`}</strong>{` mot bakgrunnsfargen.`}</p>
    <h2 {...{
      "id": "eksempler-på-kontrastnivå"
    }}>{`Eksempler på kontrastnivå:`}</h2>
    <GridContainer spacing="large" mdxType="GridContainer">
  <GridItem small={4} mdxType="GridItem">
    <div className="eds-contrast__practice eds-contrast__practice--dont">
      <Heading3 margin="bottom" style={{
            color: colors.blues.blue60
          }} mdxType="Heading3">
        Om Entur
      </Heading3>
      <Paragraph style={{
            color: colors.blues.blue60
          }} mdxType="Paragraph">
        Entur leverer tjenester som gjør det enkelt å reise på tvers av alle
        kollektivselskaper i Norge.
      </Paragraph>
    </div>
    <div>
      <span className="eds-contrast__header">
        <ValidationErrorFilledIcon color={colors.validation.lava} mdxType="ValidationErrorFilledIcon" />
        <span className="eds-contrast__header-text">Unngå</span>
      </span>
      <div>
        Slik ser det ut med kontrast 1.7:1. Dette er ikke innafor
        kontrast-kravet og ikke godkjent."
      </div>
    </div>
  </GridItem>
  <GridItem small={4} mdxType="GridItem">
    <div className="eds-contrast__practice eds-contrast__practice--do">
      <Heading3 margin="bottom" style={{
            color: colors.blues.blue40
          }} mdxType="Heading3">
        Om Entur
      </Heading3>
      <Paragraph style={{
            color: colors.blues.blue40
          }} mdxType="Paragraph">
        Entur leverer tjenester som gjør det enkelt å reise på tvers av alle
        kollektivselskaper i Norge.
      </Paragraph>
    </div>
    <div>
      <span className="eds-contrast__header">
        <ValidationSuccessFilledIcon color={colors.validation.mint} mdxType="ValidationSuccessFilledIcon" />{' '}
        <span className="eds-contrast__header-text">Innafor</span>
      </span>
      <div>
        Slik ser det ut med kontrast 5.5:1. Dette er innafor kontrastkravet.
      </div>
    </div>
  </GridItem>
  <GridItem small={4} mdxType="GridItem">
    <div className="eds-contrast__practice eds-contrast__practice--do">
      <Heading3 margin="bottom" mdxType="Heading3">Om Entur</Heading3>
      <Paragraph mdxType="Paragraph">
        Entur leverer tjenester som gjør det enkelt å reise på tvers av alle
        kollektiv-selskaper i Norge.
      </Paragraph>
    </div>
    <div>
      <span className="eds-contrast__header">
        <ValidationSuccessFilledIcon color={colors.validation.mint} mdxType="ValidationSuccessFilledIcon" />{' '}
        <span className="eds-contrast__header-text">Innafor</span>
      </span>
      <div>
        Slik ser det ut med maks kontrast 15.6:1. Dette er veldig innafor!
      </div>
    </div>
  </GridItem>
    </GridContainer>
    <h2 {...{
      "id": "color-contrast-checker"
    }}>{`Color Contrast Checker`}</h2>
    <p>{`Bruk vår Color Contrast Checker for å bestemme lesbarheten til tekst og kontrasten til grafiske objekter.`}</p>
    <ContrastChecker mdxType="ContrastChecker" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      